import { graphql, PageProps } from "gatsby";
import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Navbar from "../../components/navbar";
import NotificationBar from "../../components/notification-bar";
import { TideItemForSeizoenWedstrijd, TideItemForTagPage, TideItemForUspFolder, TideItemForWebsite } from "../../types";
import { getChildItemsWithTypename, parse } from "../../utils/component";
import Seo from "../../components/seo";
import GlobalContext from "../../contexts/global-provider";
import MapTagPageComponents from "../../utils/mappers/map-tag-page-components";
import { PackageProvider } from "../../contexts/package-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

export interface TagPageTemplateData {
  website: TideItemForWebsite;
  page: TideItemForTagPage;
  usps: TideItemForUspFolder;
}

interface TagPageTemplateContext {
  language: string;
  breadcrumbs: Breadcrumb[];
  tagId: string;
}

const TagPageTemplate: React.FC<PageProps<TagPageTemplateData, TagPageTemplateContext>> = ({
  data,
  pageContext: { language, breadcrumbs, tagId },
}) => {
  const globalContext = useContext(GlobalContext);
  const [eventIds, setEventIds] = useState<string[]>([]);
  const { games, catalogueIds, notificationBar, primaryNavigation, setGlobalData } = useContext<ComponentContextType>(ComponentContext);

  useEffect(() => {
    (async () => {
      if (globalContext && globalContext.language !== language) {
        globalContext.setLanguage(language);
      }
      if (!games || games.length == 0) {
        await setGlobalData();
      }
    })();
  }, []);

  useEffect(() => {
    if (games && !isEmpty(catalogueIds)) {
      setEventIds(
        games
          .filter((g) => g.content?.general?.tagsIds?.includes(tagId))
          .flatMap(
            (game) =>
              (getChildItemsWithTypename("TideItemForSeizoenWedstrijd", game) as TideItemForSeizoenWedstrijd[])
                .filter((es) => es.content?.general?.eventStartDate && new Date(es.content.general.eventStartDate) >= new Date())
                .map((es) => es.content?.general?.id) as string[]
          )
      );
    }
  }, [games, catalogueIds]);

  return (
    <>
      {primaryNavigation && (
        <Layout>
          <Seo
            title={parse(data.page.content?.seo?.seoTitle)}
            description={parse(data.page.content?.seo?.seoDescription)}
            keywords={parse(data.page.content?.seo?.seoKeywords)}
            website={parse(data.website)}
            breadcrumbs={parse(breadcrumbs)}
          />
          {!isEmpty(notificationBar?.content?.general?.notification) && (
            <NotificationBar
              text={notificationBar?.content?.general?.notification ?? ""}
              icon={parse(notificationBar?.content?.general?.iconFontAwesome)}
            />
          )}
          <Navbar website={parse(data.website)} />
          {globalContext.language === language && games.length > 0 && (
            <PackageProvider eventIds={eventIds}>
              <MapTagPageComponents website={data.website} page={data.page} usps={data.usps} breadcrumbs={breadcrumbs} />
            </PackageProvider>
          )}
          <Footer name={parse(data.website.name)} logo={parse(data.website.content?.general?.logo)} />
        </Layout>
      )}
    </>
  );
};

export default TagPageTemplate;
export const query = graphql`
  query TagPageQuery($pageId: String, $websiteId: String) {
    website: tideItemForWebsite(id: { eq: $websiteId }) {
      name
      content {
        general {
          phone
          helptext
          linkSgr
          linkThuiswinkelWaarborg
          linkTelephone
          logo {
            altText
            url
            title
          }
          linkLogo
        }
      }
    }
    page: tideItemForTagPage(id: { eq: $pageId }) {
      id
      name
      content {
        general {
          title
          tagId
        }
        seo {
          seoTitle
          seoDescription
          seoKeywords
        }
      }
      childItems {
        ...TideItemForHeroComponentFragment
        ...TideItemForIntroComponentFragment
        ...TagPageOverviewComponentFragment
        ...TideItemForInfoBlocksComponentFragment
      }
    }
    usps: tideItemForUspFolder(parentItem: { id: { eq: $websiteId } }) {
      name
      childItems {
        ... on TideItemForUspItem {
          __typename
          id
          name
          content {
            general {
              fai
              text
              title
            }
          }
        }
      }
    }
  }
`;
